<template>
    <div class="add-box">
        <i-header :title="title" @back-event="toBack"></i-header>
        <div class="cnt-box">
            <div class="item-box" v-for="(item, index) in OldData" :key="index">
                <div class="left">
                    <div class="name">{{ item.name }}</div>
                    <div class="desc">预算金额：{{ item.budget | currencyFormatter("￥") }}</div>
                    <div class="desc">结算金额：{{ item.settlement | currencyFormatter("￥") }}</div>
                    <div class="desc">偏差说明：</div>
                    <template v-if="readonly">
                        <div class="desc">{{ item.deviationDescribe }}</div>
                    </template>
                    <div class="desc" v-else>
                        <textarea placeholder="请填写偏差说明" maxlength="500" v-model="item.deviationDescribe" style="border: 1px solid #d4d4d4; box-sizing: border-box; width: 100%; padding: 10px" cols="60" rows="5" id="contactus" name="contactus" />
                    </div>
                </div>
            </div>
        </div>
        <br />
        <div class="cnt-box" v-if="!readonly">
            <i-button :loading="loading" @click="submit" :disabled="loading" loading-text="保存" block round type="primary" size="normal"> 保存 </i-button>
        </div>
    </div>
</template>

<script>
export default {
    name: "index",
    components: {},
    data() {
        return {
            title: "偏差说明",
            loading: false,
            OldData: [],
            readonly: !!this.$route.query.readonly,
        };
    },
    created() {
        this.init();
        //初始化

        this.OldData = this.$route.query.OldData;

        this.OldData = [
            {
                name: "外卖",
                budget: 1243,
                settlement: 12322,
                deviationDescribe: "说明了一下2",
            },
            {
                name: "外出用餐",
                budget: 1236,
                settlement: 11232,
                deviationDescribe: "说明了一下1",
            },
            {
                name: "酒店会场",
                budget: 1223,
                settlement: 12332,
                deviationDescribe: "说明了一下3",
            },
        ];
    },
    methods: {
        async init() {},
        toBack() {
            this.$router.back();
        },
        submit() {
            if (this.OldData) {
                console.log("====提交偏差说明", this.OldData);
                this.loading = true;

                setTimeout(() => {
                    this.loading = false;
                    this.$router.back();
                }, 500);

                // this.$service
                //     .saveEventSummary({
                //         miceId: this.$route.query.miceId,
                //         situationDescription: this.OldData,
                //     })
                //     .then((res) => {
                //         this.loading = false;
                //         if (res.success) {
                //             this.$router.back();
                //         } else {
                //             this.$itoast.fail("订单错误,请联系管理员！");
                //         }
                //     });
            }
        },
    },
};
</script>

<style lang="less" scoped>
.add-box {
    width: 100vw;
    height: 100vh;
    background-color: #ffffff;
    .cnt-box {
        padding: 0 0.25rem;
        box-sizing: border-box;

        
        .count {
            font-size: 0.12rem;
            color: #909398;
            margin-top: 0.2rem;
        }
    }

    .item-box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 0.27rem;
        background: #f3f5f5;
        padding: 10px;
        border-radius: 10px;

        .left {
            .name {
                font-size: 0.16rem;
                color: #13161b;
                margin-bottom: 0.1rem;
                font-weight: 700;
            }

            .desc {
                font-size: 0.14rem;
                color: #13161b;
            }
        }

        .right {
            display: flex;

            svg {
                width: 0.2rem;
                height: 0.2rem;
                display: block;
            }

            :nth-child(2) {
                margin-left: 0.18rem;
            }
        }
    }
    .submit-btn {
        width: 88%;
        height: 0.45rem;
        line-height: 0.45rem;
        border-radius: 0.3rem;
        margin: 11.73vw auto 0 auto;
        font-size: 0.15rem;
        background: var(--themeColor);
        display: block;
        color: #fff;
        text-align: center;
    }
}
</style>
