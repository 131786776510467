var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "add-box" },
    [
      _c("i-header", {
        attrs: { title: _vm.title },
        on: { "back-event": _vm.toBack },
      }),
      _c(
        "div",
        { staticClass: "cnt-box" },
        _vm._l(_vm.OldData, function (item, index) {
          return _c("div", { key: index, staticClass: "item-box" }, [
            _c(
              "div",
              { staticClass: "left" },
              [
                _c("div", { staticClass: "name" }, [_vm._v(_vm._s(item.name))]),
                _c("div", { staticClass: "desc" }, [
                  _vm._v(
                    "预算金额：" +
                      _vm._s(_vm._f("currencyFormatter")(item.budget, "￥"))
                  ),
                ]),
                _c("div", { staticClass: "desc" }, [
                  _vm._v(
                    "结算金额：" +
                      _vm._s(_vm._f("currencyFormatter")(item.settlement, "￥"))
                  ),
                ]),
                _c("div", { staticClass: "desc" }, [_vm._v("偏差说明：")]),
                _vm.readonly
                  ? [
                      _c("div", { staticClass: "desc" }, [
                        _vm._v(_vm._s(item.deviationDescribe)),
                      ]),
                    ]
                  : _c("div", { staticClass: "desc" }, [
                      _c("textarea", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: item.deviationDescribe,
                            expression: "item.deviationDescribe",
                          },
                        ],
                        staticStyle: {
                          border: "1px solid #d4d4d4",
                          "box-sizing": "border-box",
                          width: "100%",
                          padding: "10px",
                        },
                        attrs: {
                          placeholder: "请填写偏差说明",
                          maxlength: "500",
                          cols: "60",
                          rows: "5",
                          id: "contactus",
                          name: "contactus",
                        },
                        domProps: { value: item.deviationDescribe },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              item,
                              "deviationDescribe",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
              ],
              2
            ),
          ])
        }),
        0
      ),
      _c("br"),
      !_vm.readonly
        ? _c(
            "div",
            { staticClass: "cnt-box" },
            [
              _c(
                "i-button",
                {
                  attrs: {
                    loading: _vm.loading,
                    disabled: _vm.loading,
                    "loading-text": "保存",
                    block: "",
                    round: "",
                    type: "primary",
                    size: "normal",
                  },
                  on: { click: _vm.submit },
                },
                [_vm._v(" 保存 ")]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }